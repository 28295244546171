.storySnap {
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  /* border: 1px solid lightpink; */
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
