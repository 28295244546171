.articleHead {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
}

.imgBlock {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.imgBlock img
{
    width: 100%;
}

.textBlock {
  width: 50%;
  height: 70vh;
  display: flex;
  /* margin-right: 8rem; */
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  line-height: 2.5rem;
}
