.navbar {
  background-color: #f96666;
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navLogo {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  color: #ffffff;
}

.navLink {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid red; */
  color: #ffffff;
  padding: 1rem;
  /* margin-right: 1rem; */
}

.navLink p {
  font-size: 25px;
  text-decoration: none;
  color: #ffffff;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchContainer input {
  padding: 0.5rem;
  border-radius: 8px;
  background: transparent;
  border: 0.1px solid darkgray;
}
