* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #343434; */
}

.App {
  text-align: center;
  min-height: 100vh;
  
}

#root
{
    position: relative;
    padding-bottom: 80px;
}

.footer{
    position: absolute;
    bottom: 0px;
    padding-top: 15px;
        padding-bottom: 15px;
        height : auto !important;
    
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.searchContainer svg
{
    right: 15px;
}

.continue-reading
{
    font-size: 1.25rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.css-bcqb1l-MuiContainer-root
{
    width: 70%;
}

.short_story_image
{
    width: 20%;
}

.short_story_text
{
    width: 80%;
}

.searchContainer
    {
        position: relative;
    }

.story_image_section, .story_body_section
{
    width: 50%;
}

.story_page_container
{
    display: flex;

}

.contactUsContainer
{
    display: flex;
    padding-top: 25px;
    height: calc(100vh - 130px);
}

.social_link_each_container
{
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 30px;
}

.social_link_a
{
    display: block;
    float: left;
}

.socialLinkImg
{
    width: 100%;
    max-width: 200px;
}

.joinUsContainer
{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
        display: flex;
    min-height: calc(100vh - 130px);
    flex-wrap: wrap;
}

.mobile_burger
{
    display: none;
}

.mobile_burger_menu_close
{
    display: none;
}

a h3, a h4, a h5, a u, a p
{
    color: black;

}

.storySnap, .no-dec
{
    text-decoration: none;
}

.social_media_container
{
    width: 100%;
    display: flex;
}

.social_media_container:nth-child(3)
{
    padding-left: 25%;
    padding-right: 25%;
}

.articleHead
{
    display: flex !important;
}

.slick-prev, .slick-next
{
    display: none;
}

.continue_reading_container a
{
    display: block;
}

@media screen and (max-width: 1400px)
{
    h2
    {
        font-size: 22px;
            line-height: 40px;
    }
    
    h3{
            font-size: 19px;
        line-height: 34px;
    }
    
    h4{
            font-size: 15px;
        line-height: 28px;
    }
    
     h5{
            font-size: 14px !important;
        line-height: 25px !important;
    }
    
    .navLogo
    {
        width: 40% !important;
    }
    
    .searchContainer svg
    {
        //right: 35px;
    }
    
    .continue-reading
    {
        font-size: 14px;
    }
    
    .short_story_image
    {
        width: 40%;
    }
    
    .short_story_image img
    {
        width: 100%;
    }

    .short_story_text
    {
        width: 60%;
        height: auto;
    }
    
    .footer
    {
            
    }
}


@media screen and (max-width:1024px)
{
    .css-bcqb1l-MuiContainer-root
    {
        width: calc(100% - 30px) !important;
    }
}


@media screen and (max-width: 768px)
{
    .imgBlock, .textBlock
    {
        width: 100% !important;
        height : auto !important;
    }
    
    .articleHead, .imgBlock
    {
        display: block !important;
        height : auto !important;
    }
    
    .textBlock div
    {
        margin : 0 auto;
    }
    
    .story_image_section, .story_body_section
    {
        width: 100%;
    }
    

    .story_page_container, .story_image_section, .story_body_section
    {
        display: block;
        
    }
    
    .navLogo
    {
        width: 84% !important;
            margin-left: 5px !important;
    }
    
    .navLink
    {
        display: none !important;
    }
    
    .navLink.active
    {
        display: block !important;
        width: 100%;
        position: fixed;
        height: 100vh;
        background-color: black;
        z-index: 10000;
        top: 0px;
    }
    
    .navLink.active a h5
    {
        font-size: 20px !important;
        margin-top: 15px;
        display: block !important;
    }
    
    .navLink.active .mobile_burger_menu_close
    {
        display: block;
        color : white;
        text-align: right;
        font-size: 20px;
        cursor: pointer;
    }
    
   
    
    .searchContainer
    {
        position: relative;
    }
    
    .searchContainer input
    {
        margin-top: 15px;
        width: 100%;
    }
    
    .searchContainer svg
    {
        top: 20px;
        //right: 15px;
    }
    
    .mobile_burger
    {
        display: block;
        margin-right: 15px;
        cursor: pointer;
    }
    
    .mobile_burger span
    {
        background-color: black;
        margin-top: 5px;
        width: 30px;
        height: 2px;
            display: block;
    }
    
    
    
    
}

@media screen and (max-width: 450px)
{
    .short_story_image, .short_story_text
    {
        width: 100%;
    }
    
    .short_story_image, .short_story_text, .storySnap
    {
        display: block !important;
    }
    
    .social_link_each_container
    {
        width: 100%;
        
    }
}