body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #343434; */
}

.App {
  text-align: center;
  min-height: 100vh;
  
}

#root
{
    position: relative;
    padding-bottom: 80px;
}

.footer{
    position: absolute;
    bottom: 0px;
    padding-top: 15px;
        padding-bottom: 15px;
        height : auto !important;
    
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.searchContainer svg
{
    right: 15px;
}

.continue-reading
{
    font-size: 1.25rem;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.css-bcqb1l-MuiContainer-root
{
    width: 70%;
}

.short_story_image
{
    width: 20%;
}

.short_story_text
{
    width: 80%;
}

.searchContainer
    {
        position: relative;
    }

.story_image_section, .story_body_section
{
    width: 50%;
}

.story_page_container
{
    display: flex;

}

.contactUsContainer
{
    display: flex;
    padding-top: 25px;
    height: calc(100vh - 130px);
}

.social_link_each_container
{
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 30px;
}

.social_link_a
{
    display: block;
    float: left;
}

.socialLinkImg
{
    width: 100%;
    max-width: 200px;
}

.joinUsContainer
{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
        display: flex;
    min-height: calc(100vh - 130px);
    flex-wrap: wrap;
}

.mobile_burger
{
    display: none;
}

.mobile_burger_menu_close
{
    display: none;
}

a h3, a h4, a h5, a u, a p
{
    color: black;

}

.storySnap, .no-dec
{
    text-decoration: none;
}

.social_media_container
{
    width: 100%;
    display: flex;
}

.social_media_container:nth-child(3)
{
    padding-left: 25%;
    padding-right: 25%;
}

.articleHead
{
    display: flex !important;
}

.slick-prev, .slick-next
{
    display: none;
}

.continue_reading_container a
{
    display: block;
}

@media screen and (max-width: 1400px)
{
    h2
    {
        font-size: 22px;
            line-height: 40px;
    }
    
    h3{
            font-size: 19px;
        line-height: 34px;
    }
    
    h4{
            font-size: 15px;
        line-height: 28px;
    }
    
     h5{
            font-size: 14px !important;
        line-height: 25px !important;
    }
    
    .navLogo
    {
        width: 40% !important;
    }
    
    .searchContainer svg
    {
        //right: 35px;
    }
    
    .continue-reading
    {
        font-size: 14px;
    }
    
    .short_story_image
    {
        width: 40%;
    }
    
    .short_story_image img
    {
        width: 100%;
    }

    .short_story_text
    {
        width: 60%;
        height: auto;
    }
    
    .footer
    {
            
    }
}


@media screen and (max-width:1024px)
{
    .css-bcqb1l-MuiContainer-root
    {
        width: calc(100% - 30px) !important;
    }
}


@media screen and (max-width: 768px)
{
    .imgBlock, .textBlock
    {
        width: 100% !important;
        height : auto !important;
    }
    
    .articleHead, .imgBlock
    {
        display: block !important;
        height : auto !important;
    }
    
    .textBlock div
    {
        margin : 0 auto;
    }
    
    .story_image_section, .story_body_section
    {
        width: 100%;
    }
    

    .story_page_container, .story_image_section, .story_body_section
    {
        display: block;
        
    }
    
    .navLogo
    {
        width: 84% !important;
            margin-left: 5px !important;
    }
    
    .navLink
    {
        display: none !important;
    }
    
    .navLink.active
    {
        display: block !important;
        width: 100%;
        position: fixed;
        height: 100vh;
        background-color: black;
        z-index: 10000;
        top: 0px;
    }
    
    .navLink.active a h5
    {
        font-size: 20px !important;
        margin-top: 15px;
        display: block !important;
    }
    
    .navLink.active .mobile_burger_menu_close
    {
        display: block;
        color : white;
        text-align: right;
        font-size: 20px;
        cursor: pointer;
    }
    
   
    
    .searchContainer
    {
        position: relative;
    }
    
    .searchContainer input
    {
        margin-top: 15px;
        width: 100%;
    }
    
    .searchContainer svg
    {
        top: 20px;
        //right: 15px;
    }
    
    .mobile_burger
    {
        display: block;
        margin-right: 15px;
        cursor: pointer;
    }
    
    .mobile_burger span
    {
        background-color: black;
        margin-top: 5px;
        width: 30px;
        height: 2px;
            display: block;
    }
    
    
    
    
}

@media screen and (max-width: 450px)
{
    .short_story_image, .short_story_text
    {
        width: 100%;
    }
    
    .short_story_image, .short_story_text, .storySnap
    {
        display: block !important;
    }
    
    .social_link_each_container
    {
        width: 100%;
        
    }
}
.navbar {
  background-color: #f96666;
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navLogo {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  color: #ffffff;
}

.navLink {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid red; */
  color: #ffffff;
  padding: 1rem;
  /* margin-right: 1rem; */
}

.navLink p {
  font-size: 25px;
  text-decoration: none;
  color: #ffffff;
}

.searchContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchContainer input {
  padding: 0.5rem;
  border-radius: 8px;
  background: transparent;
  border: 0.1px solid darkgray;
}

.articleHead {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
}

.imgBlock {
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.imgBlock img
{
    width: 100%;
}

.textBlock {
  width: 50%;
  height: 70vh;
  display: flex;
  /* margin-right: 8rem; */
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  line-height: 2.5rem;
}

#slider {
  position: relative;
  width: 50%;
  height: 30vw;
  margin: 80px auto;
  font-family: "Helvetica Neue", sans-serif;
  -webkit-perspective: 1400px;
          perspective: 1400px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

input[type="radio"] {
  position: relative;
  top: 108%;
  left: 50%;
  width: 18px;
  height: 18px;
  margin: 0 15px 0 0;
  opacity: 0.4;
  -webkit-transform: translateX(-83px);
          transform: translateX(-83px);
  cursor: pointer;
}

input[type="radio"]:nth-child(5) {
  margin-right: 0px;
}

input[type="radio"]:checked {
  opacity: 1;
}

#slider label {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: white;
  font-size: 70px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-transform 400ms ease;
  transition: transform 400ms ease;
  transition: transform 400ms ease, -webkit-transform 400ms ease;
}

#slide1 {
  background: tomato;
}

#slide2 {
  background: yellowgreen;
}

#slide3 {
  background: dodgerblue;
}

#slide4 {
  background: slateblue;
}

#slide5 {
  background: violet;
}

/* Slider Functionality */

/* Active Slide */
#s1:checked ~ #slide1,
#s2:checked ~ #slide2,
#s3:checked ~ #slide3,
#s4:checked ~ #slide4,
#s5:checked ~ #slide5 {
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.3), 0 12px 6px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(0%, 0, 0px);
          transform: translate3d(0%, 0, 0px);
}

/* Next Slide */
#s1:checked ~ #slide2,
#s2:checked ~ #slide3,
#s3:checked ~ #slide4,
#s4:checked ~ #slide5,
#s5:checked ~ #slide1 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(15%, 0, -100px);
          transform: translate3d(15%, 0, -100px);
}

/* Next to Next Slide */
#s1:checked ~ #slide3,
#s2:checked ~ #slide4,
#s3:checked ~ #slide5,
#s4:checked ~ #slide1,
#s5:checked ~ #slide2 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  -webkit-transform: translate3d(30%, 0, -250px);
          transform: translate3d(30%, 0, -250px);
}

/* Previous to Previous Slide */
#s1:checked ~ #slide4,
#s2:checked ~ #slide5,
#s3:checked ~ #slide1,
#s4:checked ~ #slide2,
#s5:checked ~ #slide3 {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  -webkit-transform: translate3d(-30%, 0, -250px);
          transform: translate3d(-30%, 0, -250px);
}

/* Previous Slide */
#s1:checked ~ #slide5,
#s2:checked ~ #slide1,
#s3:checked ~ #slide2,
#s4:checked ~ #slide3,
#s5:checked ~ #slide4 {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3), 0 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate3d(-15%, 0, -100px);
          transform: translate3d(-15%, 0, -100px);
}

.storySnap {
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  /* border: 1px solid lightpink; */
  border-radius: 5px;
  margin-bottom: 2rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.upcoming {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
}

.footer {
  background-color: #f96666;
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chapterContainer {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 2rem;
}

